/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, all } from 'redux-saga/effects';
import {
  ApiDrop,
  ApiDropUpdate,
  ApiAddNftsToDrip,
  ExportedDrop,
  ApiGetDrops,
} from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getDropsSuccess,
  getDropsFailure,
  getDropSuccess,
  importDropSuccess,
  importDropFailure,
} from './reducer';
import { getNFTsSuccess } from '../nft/reducer';
import { history } from 'navigation';
import { generatePath } from 'react-router';
import routes from 'navigation/paths';
import { IImportNFTData, INFTsData, ITemplate } from '../nft/types';
import { importNftSaga } from '../nft/saga';
import { createTemplateRequest } from '../nft/utils';
import { generateErrorMessage } from 'utils';
import api from 'api/middleware';
import config from 'api/config';

export function* getDrops({ payload }: PayloadAction<ApiGetDrops>) {
  try {
    const {
      data: { list, amount },
    } = yield call(api, config.drops, { params: payload });
    yield put(getDropsSuccess({ list, amount }));
  } catch (e) {
    yield put(getDropsFailure(generateErrorMessage(e)));
  }
}

function* uploadFile(serviceName: string, file?: File) {
  if (!file || !serviceName) return undefined;

  const fd = new FormData();
  fd.append('files', file);

  const {
    data: { urls },
  } = yield call(api.post, config.dropFiles(serviceName), fd, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return urls[0] as string;
}

function* uploadFiles(serviceName: string, files?: File[]) {
  if (!files || !files.length || !serviceName) return [];

  const fd = new FormData();
  files.forEach((file) => fd.append('files', file));

  const {
    data: { urls },
  } = yield call(api.post, config.dropFiles(serviceName), fd, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return urls as string[];
}

export function* createDrop({ payload }: PayloadAction<ApiDrop>) {
  const { bannerFile, creatorLogoFiles, ...payloadWithoutFile } = payload;

  try {
    const bannerUrl = yield* uploadFile(
      payloadWithoutFile.serviceName,
      bannerFile,
    );
    const creatorLogos = yield* uploadFiles(
      payloadWithoutFile.serviceName,
      creatorLogoFiles,
    );
    const { data } = yield call(api.post, config.drops, {
      ...payloadWithoutFile,
      bannerUrl,
      creatorLogoUrls: creatorLogos,
    });
    history.push(generatePath(routes.drop, { dropId: data.id }));
  } catch (e) {
    yield put(getDropsFailure(generateErrorMessage(e)));
  }
}

export function* getDrop({ payload }: PayloadAction<string>) {
  try {
    const { data } = yield call(api, config.dropByIdOrServiceName(payload));
    yield put(getDropSuccess({ drop: data }));
  } catch (e) {
    yield put(getDropsFailure(generateErrorMessage(e)));
  }
}

export function* deleteDrop({ payload }: PayloadAction<string>) {
  try {
    yield call(api.delete, config.dropByIdOrServiceName(payload));
    history.push(generatePath(routes.drops));
  } catch (e) {
    yield put(getDropsFailure(generateErrorMessage(e)));
  }
}

export function* updateDrop({ payload }: PayloadAction<ApiDropUpdate>) {
  try {
    const {
      id,
      bannerFile,
      bannerUrl: previousBannerUrl,
      creatorLogoFiles,
      creatorLogoUrls: previousLogoUrls,
      ...data
    } = payload;

    let bannerUrl = previousBannerUrl;
    
    if (bannerFile) {
      bannerUrl = yield* uploadFile(payload.serviceName, bannerFile);
    }

    const newCreatorLogoUrls = yield* uploadFiles(
      payload.serviceName,
      creatorLogoFiles,
    );

    const combinedCreatorLogoUrls = [
      ...(previousLogoUrls || []),
      ...(newCreatorLogoUrls || []),
    ];

    yield call(api.patch, config.dropByIdOrServiceName(String(id)), {
      ...data,
      bannerUrl: bannerUrl || null,
      creatorLogoUrls: combinedCreatorLogoUrls.length
        ? combinedCreatorLogoUrls
        : null,
    });

    history.push(generatePath(routes.drop, { dropId: id }));
  } catch (e) {
    yield put(getDropsFailure(generateErrorMessage(e)));
  }
}

export function* addNftsToDrop({ payload }: PayloadAction<ApiAddNftsToDrip>) {
  try {
    const { id, itemIds } = payload;

    yield all(
      itemIds.map((nftId) =>
        call(api.patch, config.nftById(nftId.toString()), {
          dropId: id,
        }),
      ),
    );

    // fetch connected to drip NFTs:
    // payload for getAllNFTs: { limit, offset, filter, search, lazy = true }

    const { data }: { data: INFTsData } = yield call(api, config.nfts, {
      params: { dropId: id },
    });
    yield put(getNFTsSuccess({ ...data, list: data.list }));
  } catch (e) {
    yield put(getDropsFailure(generateErrorMessage(e)));
  }
}

export function* importDrop({ payload }: PayloadAction<ExportedDrop>) {
  try {
    // Create drop
    const {
      data: { id: dropId },
    } = yield call(api.post, config.drops, {
      name: payload.name,
      serviceName: payload.serviceName,
      startDate: new Date(payload.startDate),
    });

    if (payload.templates) {
      const {
        data: { templates },
      }: { data: { templates: ITemplate[] } } = yield call(
        api,
        config.templates,
      );
      for (const template of payload.templates) {
        const isSet = templates.some((t) => t.title === template.title);
        if (!isSet) {
          // Create template if does not exist
          const templateRequest = createTemplateRequest(template);
          yield call(api.post, config.templates, { data: templateRequest });
        }
      }
    }

    // Create NFT for each item in nfts array
    for (const nft of payload.nfts) {
      yield call(
        importNftSaga.bind(null, {
          type: '',
          payload: { ...nft, dropId } as IImportNFTData,
        }),
      );
    }

    yield put(importDropSuccess());
    history.push(generatePath(routes.drop, { dropId }));
  } catch (e) {
    yield put(importDropFailure(generateErrorMessage(e)));
  }
}
