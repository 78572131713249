import { ToastOptions } from 'react-toastify';

export const sizePerPageList = [
  {
    text: '10',
    value: 10,
  },
  {
    text: '25',
    value: 25,
  },
  {
    text: '50',
    value: 50,
  },
];

export const toastSettings: ToastOptions<{
  position: string;
  autoClose: number;
  hideProgressBar: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
  draggable: boolean;
  progress: undefined;
}> = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const OrderStatus = {
  CREATED: 'CREATED',
  READYTOPAY: 'READYTOPAY',
  WAITING: 'WAITING',
  FULFILLING: 'FULFILLING',
  FULFILLED: 'FULFILLED',
  CANCELED: 'CANCELED',
  FAILED: 'FAILED',
  REFUNDED: 'REFUNDED',
};
type OrderStatusesKeys = keyof typeof OrderStatus;
export type IStatus = typeof OrderStatus[OrderStatusesKeys];

export const UploadFilesTypes = {
  IMAGE_URLS: 'imageUrls',
  ADDITIONAL_FILES: 'additionalFiles',
  FAKE_3D_IMAGES: 'fake3dImages',
  FILE_URLS_FOR_FREEZE: 'fileUrlsForFreeze',
  COVE_IMAGE_URL: 'coverImageUrl',
  COVER_VIDEO_URL: 'coverVideoUrl',
  OG_IMAGE_URL: 'ogImageUrl',
  GLB_URL: 'glbUrl',
  USDZ_URL: 'usdzUrl',
};

export const globalStrPattern = "^[^']+$";

export const PaymentProvider = {
  STRIPE: 'STRIPE',
  COINBASE: 'COINBASE',
  PROMO: 'PROMO',
  TWIG: 'TWIG',
  FREE: 'FREE',
  LYX: 'LYX',
};

export const ItemMetaType = {
  USE_CASE: 'USE_CASE',
  TRANSPARENCY: 'TRANSPARENCY',
  BADGES: 'BADGE',
  METADATA: 'METADATA',
};
type ItemMetaTypeKeys = keyof typeof ItemMetaType;
export type IItemMetaType = typeof ItemMetaType[ItemMetaTypeKeys];

interface IBlockchain {
  name: string;
  etherscanLink?: string;
  looksrareLink?: string;
  openseaLink?: string;
  explorerLink?: (address: string) => string;
  assetLink?: (address: string) => string;
  walletLink?: (address: string) => string;
}

export const BLOCKCHAIN_BY_CHAIN_ID: Record<string, IBlockchain> = {
  '1': {
    name: 'Ethereum',
    etherscanLink: 'https://etherscan.io/token/',
    looksrareLink: 'https://looksrare.org/collections/',
    openseaLink: 'https://opensea.io/assets/ethereum/',
  },
  '4': {
    name: 'Rinkeby',
    etherscanLink: 'https://rinkeby.etherscan.io/token/',
    looksrareLink: '',
    openseaLink: 'https://testnets.opensea.io/assets/rinkeby/',
  },
  '5': {
    name: 'Goerli',
    etherscanLink: 'https://goerli.etherscan.io/token/',
    looksrareLink: 'https://goerli.looksrare.org/collections/',
    openseaLink: 'https://testnets.opensea.io/assets/goerli/',
  },
  '22': {
    name: 'Lukso L14',
    assetLink: (address: string) =>
      `https://l14.universalprofile.cloud/asset/${address}`,
    walletLink: (address: string) =>
      `https://l14.universalprofile.cloud/${address}`,
  },
  '2201': {
    name: 'Lukso L14 Testnet',
    assetLink: (address: string) =>
      `https://l14.universalprofile.cloud/asset/${address}`,
    walletLink: (address: string) =>
      `https://l14.universalprofile.cloud/${address}`,
  },
  '42': {
    name: 'Lukso L16',
    explorerLink: (address: string) =>
      `https://explorer.execution.mainnet.lukso.network/address/${address}`,
    assetLink: (address: string) =>
      `https://universalprofile.cloud/asset/${address}?network=mainnet`,
    walletLink: (address: string) =>
      `https://universalprofile.cloud/${address}?network=mainnet`,
  },
  '4201': {
    name: 'Lukso L16 Testnet',
    explorerLink: (address: string) =>
      `https://explorer.execution.testnet.lukso.network/address/${address}`,
    assetLink: (address: string) =>
      `https://universalprofile.cloud/asset/${address}?network=testnet`,
    walletLink: (address: string) =>
      `https://universalprofile.cloud/${address}?network=testnet`,
  },
  '137': {
    name: 'Polygon',
    etherscanLink: 'https://polygonscan.com/address/',
    looksrareLink: '',
    openseaLink: 'https://opensea.io/assets/matic/',
  },
  '1337': {
    name: 'Ganache',
    etherscanLink: '',
    looksrareLink: '',
    openseaLink: '',
  },
};

export const LUKSO_L14_CHAIN_ID =
  process.env.REACT_APP_ENV === 'prod' ? 22 : 2201;

export const LUKSO_L16_CHAIN_ID =
  process.env.REACT_APP_ENV === 'prod' ? 42 : 4201;

export enum LUKSO_L16_CONTRACT_TYPE {
  LSP7 = 'LSP7',
  LSP8 = 'LSP8',
}

export const UNKNOWN_BLOCKCHAIN = {
  name: 'Unknown',
  etherscanLink: '',
  looksrareLink: '',
  openseaLink: '',
};

export const METADATA_STATUSES = {
  NOTINITIATED: 'NOTINITIATED',
  CREATED: 'CREATED',
  ASSETS_UPLOADED: 'ASSETS_UPLOADED',
  READY: 'READY',
  FROZEN: 'FROZEN',
  FAILED: 'FAILED',
};

export const CONTRACTS_MINT_ONLY = [
  'ERC721Minimal',
  'DematOnlyLazy',
  'DematLazySupply',
];

export const CONTRACTS_SUPPORT_CREATION = [
  'ERC1155',
  'DematDirectAndLazy',
  'DematMinimal',
  'DematLazySupply',
];
