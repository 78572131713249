import {
  exportOrdersToCsvRequest,
  getOrderRequest,
  getOrdersRequest,
  getOrderStatusesRequest,
} from 'redux/orders/reducer';
import {
  copyPasteConfigRequest,
  createConfigRequest,
  deleteManyConfigsRequest,
  encryptDataRequest,
  getConfigByIdOrNameRequest,
  getConfigsRequest,
  getConfigTypeRequest,
  getConfigTypesRequest,
  updateConfigRequest,
  uploadConfigFilesRequest,
} from 'redux/config/reducer';
import {
  getAuctionByNftIdRequest,
  getAuctionsRequest,
} from 'redux/auction/reducer';
import {
  createNftRequest,
  createUseCaseRequest,
  createUseCaseTemplateRequest,
  deleteNFTByIdRequest,
  deleteTemplateRequest,
  deleteUseCaseRequest,
  editUseCaseRequest,
  getAuctionNftsRequest,
  getNFTByIdRequest,
  getNftOrdersRequest,
  getNFTsRequest,
  getOwnersRequest,
  getTemplatesRequest,
  importNftRequest,
  showEarlyAccessLinkRequest,
  showShopPageRequest,
  updateNftRequest,
  updateTemplateRequest,
} from 'redux/nft/reducer';
import { logoutUser, meRequest } from 'redux/auth/reducer';
import { all, takeLatest } from 'redux-saga/effects';
import { signInRequest } from './auth/reducer';
import { logoutSaga, meSaga, signInSaga } from './auth/saga';
import { getUserRequest, getUsersRequest } from './user/reducer';
import { getUserSaga, getUsersSaga } from './user/saga';
import {
  createNFTSaga,
  createUseCaseSaga,
  createUseCaseTemplateSaga,
  deleteNFTByIdSaga,
  deleteUCTSaga,
  deleteUseCaseSaga,
  editUseCaseSaga,
  getAuctionNftsSaga,
  getNFTByIdSaga,
  getNftOrdersSaga,
  getNFTsSaga,
  getOwnersSaga,
  getTemplatesSaga,
  importNftSaga,
  showEarlyAccessLinkSaga,
  showShopPageSaga,
  updateNFTSaga,
  updateTemplateSaga,
} from './nft/saga';
import {
  createAuctionRequest,
  deleteAuctionRequest,
  getAuctionLogsRequest,
  getAuctionRequest,
  updateAuctionRequest,
} from './auction/reducer';
import {
  createAuctionSaga,
  deleteAuction,
  getAuction,
  getAuctionByIdSaga,
  getAuctionLogs,
  getAuctionsSaga,
  updateAuction,
} from './auction/saga';
import {
  copyPasteConfigSaga,
  createConfigSaga,
  deleteManyConfigsSaga,
  encryptDataSaga,
  getConfigByIdOrNameSaga,
  getConfigsSaga,
  getConfigTypeSaga,
  getConfigTypesSaga,
  updateConfigSaga,
  uploadConfigFilesSaga,
} from './config/saga';
import {
  exportOrdersToCsvSaga,
  getOrderSaga,
  getOrdersSaga,
  getOrdersStatusesSaga,
} from './orders/saga';
import {
  addCustomerToSnapshotRequest,
  exportAllCustomersRequest,
  getCustomerRequest,
  getCustomersRequest,
  sendMagicLinkRequest,
} from './customers/reducer';
import {
  addCustomerToSnapshot,
  exportAllCustomers,
  getCustomer,
  getCustomers,
  sendMagicLink,
} from './customers/saga';

import {
  addNftsToDropRequest,
  createDropRequest,
  deleteDropRequest,
  getDropRequest,
  getDropsRequest,
  importDropRequest,
  updateDropRequest,
} from './drops/reducer';
import {
  addNftsToDrop,
  createDrop,
  deleteDrop,
  getDrop,
  getDrops,
  importDrop,
  updateDrop,
} from './drops/saga';
import {
  createSnapshotRequest,
  deleteSnapshotRequest,
  exportSnapshotToCsvRequest,
  getSnapshotRequest,
  getSnapshotsRequest,
  refreshSnapshotRequest,
  removeCustomerFromSnapshotRequest,
  sendDropRequest,
  sendEmailRequest,
  sendNftsRequest,
  setDiscordRolesRequest,
} from './snapshots/reducer';
import {
  createSnapshot,
  deleteSnapshot,
  exportSnapshotToCsv,
  getSnapshot,
  getSnapshots,
  refreshSnapshot,
  removeCustomersFromSnapshot,
  sendDrop,
  sendEmail,
  sendNfts,
  setDiscordRoles,
} from './snapshots/saga';
import {
  createPromoCodesRequest,
  deletePromoCodesRequest,
  getPromoCodesRequest,
  updatePromoCodeRequest,
} from './promoCodes/reducer';
import {
  createPromoCodesSaga,
  deletePromoCodesSaga,
  getPromoCodesSaga,
  updatePromoCodeSaga,
} from './promoCodes/saga';

import { assignRolesRequest, getRolesRequest } from './discord/reducer';
import { assignRolesForCustomer, getRoles } from './discord/saga';

import {
  blockchainCreateSmartContract,
  blockchainCreateToken,
  blockchainFulfillOrdersRequest,
  createSCTemplateRequest,
  createSmartContractRequest,
  createTokenRequest,
  deleteSCTemplateRequest,
  deployL16ContractRequest,
  FreezeContractRequest,
  fulfillOrderRequest,
  getBlockChainRequest,
  getBlockChainsRequest,
  getSCTemplateRequest,
  getSmartContractRequest,
  getSmartContractsRequest,
  getTokensRequest,
  PrepareFreezeRequest,
  updateSCTemplateRequest,
  updateSmartContractRequest,
} from './blockchain/reducer';
import {
  blockchainCreateContract,
  blockchainCreateTokenSaga,
  blockchainFulfillOrders,
  createSmartContract,
  createSmartContractTemplate,
  createToken,
  deleteSmartContractTemplate,
  deployL16Contract,
  FreezeContract,
  fulFillOrders,
  getBlockChain,
  getBlockChains,
  getSmartContract,
  getSmartContracts,
  getSmartContractTemplate,
  getTokens,
  prepareFreeze,
  updateSmartContract,
  updateSmartContractTemplate,
} from './blockchain/saga';
import {
  createNewstickerRequest,
  deleteNewstickerRequest,
  getNewstickerRequest,
  getNewstickersRequest,
  updateNewstickerRequest,
} from './newstickers/reducer';
import {
  createNewstickerSaga,
  deleteNewstickerSaga,
  getNewstickerSaga,
  getNewstickersSaga,
  updateNewstickerSaga,
} from './newstickers/saga';
import {
  getCustomersTopRequest,
  getItemsSoldRequest,
  getItemsTopRequest,
  getOrdersCountRequest,
  getOrdersSumRequest,
  getOrderStatusesCountRequest,
} from './stats/reducer';
import {
  getCustomersTopSaga,
  getItemsSoldSaga,
  getItemsTopSaga,
  getOrdersCountSaga,
  getOrdersSumSaga,
  getOrderStatusesCountSaga,
} from './stats/saga';
import { uploadFilesSaga } from './storage/saga';
import { uploadFilesRequest } from './storage/reducer';
import {
  addPostRequest,
  disconnectPostsFromItemRequest,
  getPostsRequest,
} from './social/reducer';
import {
  addPostSaga,
  disconnectPostsFromItemSaga,
  getPostsSaga,
} from './social/saga';
import {
  exportShipmentsToCsvRequest,
  getShipmentRequest,
  getShipmentsRequest,
} from './shipments/reducer';
import {
  exportShipmentsToCsvSaga,
  getShipmentSaga,
  getShipmentsSaga,
} from './shipments/saga';
import { getBillingRequest } from './billing/reducer';
import { getBillingSaga } from './billing/saga';

export default function* root() {
  yield all([
    /**
     * Auth
     */
    takeLatest(signInRequest, signInSaga),
    takeLatest(meRequest, meSaga),
    takeLatest(logoutUser, logoutSaga),
    /**
     * User
     */
    takeLatest(getUsersRequest, getUsersSaga),
    takeLatest(getUserRequest, getUserSaga),
    /**
     * NFT
     */
    takeLatest(getNFTsRequest, getNFTsSaga),
    takeLatest(getNFTByIdRequest, getNFTByIdSaga),
    takeLatest(createNftRequest, createNFTSaga),
    takeLatest(updateNftRequest, updateNFTSaga),
    takeLatest(getTemplatesRequest, getTemplatesSaga),
    takeLatest(createUseCaseRequest, createUseCaseSaga),
    takeLatest(deleteUseCaseRequest, deleteUseCaseSaga),
    takeLatest(getNftOrdersRequest, getNftOrdersSaga),
    takeLatest(getOwnersRequest, getOwnersSaga),
    takeLatest(getAuctionNftsRequest, getAuctionNftsSaga),
    takeLatest(importNftRequest, importNftSaga),
    takeLatest(createUseCaseTemplateRequest, createUseCaseTemplateSaga),
    takeLatest(editUseCaseRequest, editUseCaseSaga),
    takeLatest(deleteTemplateRequest, deleteUCTSaga),
    takeLatest(updateTemplateRequest, updateTemplateSaga),
    takeLatest(deleteNFTByIdRequest, deleteNFTByIdSaga),
    takeLatest(showEarlyAccessLinkRequest, showEarlyAccessLinkSaga),
    takeLatest(showShopPageRequest, showShopPageSaga),
    /**
     * Auction
     */
    takeLatest(createAuctionRequest, createAuctionSaga),
    takeLatest(getAuctionByNftIdRequest, getAuctionByIdSaga),
    takeLatest(getAuctionRequest, getAuction),
    takeLatest(getAuctionsRequest, getAuctionsSaga),
    takeLatest(deleteAuctionRequest, deleteAuction),
    takeLatest(updateAuctionRequest, updateAuction),
    takeLatest(getAuctionLogsRequest, getAuctionLogs),
    /**
     * Configs
     */
    takeLatest(getConfigTypesRequest, getConfigTypesSaga),
    takeLatest(getConfigTypeRequest, getConfigTypeSaga),
    takeLatest(getConfigsRequest, getConfigsSaga),
    takeLatest(getConfigByIdOrNameRequest, getConfigByIdOrNameSaga),
    takeLatest(createConfigRequest, createConfigSaga),
    takeLatest(updateConfigRequest, updateConfigSaga),
    takeLatest(deleteManyConfigsRequest, deleteManyConfigsSaga),
    takeLatest(encryptDataRequest, encryptDataSaga),
    takeLatest(copyPasteConfigRequest, copyPasteConfigSaga),
    takeLatest(uploadConfigFilesRequest, uploadConfigFilesSaga),
    /**
     * Newstickers
     */
    takeLatest(getNewstickersRequest, getNewstickersSaga),
    takeLatest(getNewstickerRequest, getNewstickerSaga),
    takeLatest(createNewstickerRequest, createNewstickerSaga),
    takeLatest(updateNewstickerRequest, updateNewstickerSaga),
    takeLatest(deleteNewstickerRequest, deleteNewstickerSaga),
    /**
     * Orders
     */
    takeLatest(getOrderStatusesRequest, getOrdersStatusesSaga),
    takeLatest(getOrdersRequest, getOrdersSaga),
    takeLatest(getOrderRequest, getOrderSaga),
    takeLatest(exportOrdersToCsvRequest, exportOrdersToCsvSaga),
    /**
     * Shipments
     */
    takeLatest(getShipmentsRequest, getShipmentsSaga),
    takeLatest(getShipmentRequest, getShipmentSaga),
    takeLatest(exportShipmentsToCsvRequest, exportShipmentsToCsvSaga),
    /**
     * Billing
     */
    takeLatest(getBillingRequest, getBillingSaga),
    /**
     * Drips
     */
    takeLatest(getDropsRequest, getDrops),
    takeLatest(createDropRequest, createDrop),
    takeLatest(getDropRequest, getDrop),
    takeLatest(deleteDropRequest, deleteDrop),
    takeLatest(updateDropRequest, updateDrop),
    takeLatest(addNftsToDropRequest, addNftsToDrop),
    takeLatest(importDropRequest, importDrop),
    /**
     *
     * Customers
     */
    takeLatest(getCustomersRequest, getCustomers),
    takeLatest(getCustomerRequest, getCustomer),
    takeLatest(exportAllCustomersRequest, exportAllCustomers),
    takeLatest(addCustomerToSnapshotRequest, addCustomerToSnapshot),
    takeLatest(sendMagicLinkRequest, sendMagicLink),

    /**
     *
     * Snapshots
     */
    takeLatest(getSnapshotsRequest, getSnapshots),
    takeLatest(getSnapshotRequest, getSnapshot),
    takeLatest(createSnapshotRequest, createSnapshot),
    takeLatest(deleteSnapshotRequest, deleteSnapshot),
    takeLatest(sendEmailRequest, sendEmail),
    takeLatest(setDiscordRolesRequest, setDiscordRoles),
    takeLatest(sendDropRequest, sendDrop),
    takeLatest(sendNftsRequest, sendNfts),
    takeLatest(refreshSnapshotRequest, refreshSnapshot),
    takeLatest(exportSnapshotToCsvRequest, exportSnapshotToCsv),
    takeLatest(removeCustomerFromSnapshotRequest, removeCustomersFromSnapshot),

    /**
     *
     * PromoCodes
     */
    takeLatest(createPromoCodesRequest, createPromoCodesSaga),
    takeLatest(getPromoCodesRequest, getPromoCodesSaga),
    takeLatest(updatePromoCodeRequest, updatePromoCodeSaga),
    takeLatest(deletePromoCodesRequest, deletePromoCodesSaga),

    /**
     *
     * Discord
     */
    takeLatest(getRolesRequest, getRoles),
    takeLatest(assignRolesRequest, assignRolesForCustomer),

    /**
     *
     * Blockchain
     */
    takeLatest(getSCTemplateRequest, getSmartContractTemplate),
    takeLatest(createSCTemplateRequest, createSmartContractTemplate),
    takeLatest(updateSCTemplateRequest, updateSmartContractTemplate),
    takeLatest(getSmartContractRequest, getSmartContract),
    takeLatest(createSmartContractRequest.type, createSmartContract),
    takeLatest(updateSmartContractRequest.type, updateSmartContract),
    takeLatest(deleteSCTemplateRequest, deleteSmartContractTemplate),
    takeLatest(getSmartContractsRequest, getSmartContracts),
    takeLatest(getBlockChainRequest, getBlockChain),
    takeLatest(getTokensRequest.type, getTokens),
    takeLatest(createTokenRequest.type, createToken),
    takeLatest(blockchainCreateSmartContract, blockchainCreateContract),
    takeLatest(blockchainCreateToken, blockchainCreateTokenSaga),
    takeLatest(fulfillOrderRequest, fulFillOrders),
    takeLatest(blockchainFulfillOrdersRequest, blockchainFulfillOrders),
    takeLatest(getBlockChainsRequest, getBlockChains),
    takeLatest(PrepareFreezeRequest, prepareFreeze),
    takeLatest(FreezeContractRequest, FreezeContract),
    takeLatest(deployL16ContractRequest, deployL16Contract),

    /**
     *
     * Stats
     */
    takeLatest(getOrderStatusesCountRequest, getOrderStatusesCountSaga),
    takeLatest(getItemsTopRequest, getItemsTopSaga),
    takeLatest(getItemsSoldRequest, getItemsSoldSaga),
    takeLatest(getCustomersTopRequest, getCustomersTopSaga),
    takeLatest(getOrdersSumRequest, getOrdersSumSaga),
    takeLatest(getOrdersCountRequest, getOrdersCountSaga),

    /**
     *
     * Storage
     */
    takeLatest(uploadFilesRequest, uploadFilesSaga),

    /**
     *
     * Social media
     */
    takeLatest(getPostsRequest, getPostsSaga),
    takeLatest(addPostRequest, addPostSaga),
    takeLatest(disconnectPostsFromItemRequest, disconnectPostsFromItemSaga),
  ]);
}
