const paths = {
  home: '/',
  dashboard: '/dashboard',
  // Auth
  login: '/account/login',
  logout: '/account/logout',
  // Users
  users: '/users',
  user: '/user/:userId',
  // NFTs
  nfts: '/nfts',
  nftsDetails: '/nfts/:nftId',
  createNft: '/nfts/create/:nftId?/:step?',
  editNft: '/nfts/edit/:nftId',
  // Auctions
  auctions: '/auctions',
  auction: '/auction/:auctionId',
  // Configs
  configTypes: '/configs/types',
  configType: '/config/:configTypeName',
  config: '/config/:configTypeName/:configId',
  // Newstickers
  newstickers: '/newstickers',
  newsticker: '/newsticker/:newstickerId?',
  // Orders
  orders: '/orders',
  order: '/orders/:orderId',
  shipments: '/orders/shipments',
  // Drops
  drops: '/drips',
  dropsEdit: '/drips/edit/:dropId',
  dropsCreate: '/drips/create',
  drop: '/drips/view/:dropId',
  // Customers
  customers: '/customers',
  customersSnapshots: '/customers/snapshots',
  customersSnapshot: '/customers/snapshots/:snapshotId',
  customer: '/customers/:customerId',

  blockchain: '/blockchain',
  blockchainTemplate: '/blockchain/template/:templateId?',
  blockchainTemplateEdit: '/blockchain/template/edit/:templateId?',
  blockchainSmartContract: `/blockchain/smartcontract/view/:contractId`,
  blockchainSmartContractEdit: '/blockchain/smartcontract/edit/:contractId?',
  blockchainTokenCreate: `/blockchain/token/create`,
};
export default paths;
